import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import makeUrl from "../utils/url";

function Button({ children }) {
  return (
    <div className="bg-1 text-white py-3 px-5 mt-4 mr-4 rounded-xl hover:bg-red-500 transition duration-300">
      {children}
    </div>
  );
}

export default function IdeaBox({
  description,
  img,
  price,
  title,
  website,
  instruction,
  even,
}) {
  return (
    <div className={`${even ? "bg-1 bg-opacity-5" : ""}`}>
      <div className="container max-w-lg">
        <div className="flex flex-wrap">
          {!even && (
            <div className="w-full lg:w-1/3 p-8 max-w-lg max-h-lg">
              <Link to={`/${makeUrl(title, "idee")}`}>
                <GatsbyImage
                  image={img}
                  className="h-full w-full rounded-lg"
                  alt={`Bild ${title}`}
                />
              </Link>
            </div>
          )}
          {even === 1 && (
            <div className="lg:hidden w-full lg:w-1/3 p-8 max-w-lg max-h-lg">
              <Link to={`/${makeUrl(title, "idee")}`}>
                <GatsbyImage
                  image={img}
                  className="h-full w-full rounded-lg"
                  alt={`Bild ${title}`}
                />
              </Link>
            </div>
          )}

          <div className="w-full lg:w-2/3 p-8 leading-relaxed flex flex-wrap items-start content-start">
            <h4 className="text-3xl lg:text-3xl font-display leading-normal  text-red-800 mb-2">
              {title}
            </h4>

            <div className="text-base lg:text-lg">
              {description.split("#").map((txt, i) => (
                <p key={i}>{txt}</p>
              ))}
            </div>
            {price && (
              <div className="mx-auto mt-2 text-gray-800 text-lg w-full">
                <span className="text-md">Preis</span> ca. {price}€
              </div>
            )}
            {website && (
              <a href={website.website} target="__blank">
                <Button>
                  {price && `Hold dir das Geschenk!`}
                  {!price && "Hier gibts mehr!"}
                </Button>
              </a>
            )}
            {instruction && (
              <Link to={`/${makeUrl(instruction.title, "anleitung")}`}>
                <Button> Hier gehts zur Anleitung! </Button>
              </Link>
            )}
          </div>

          {even === 1 && (
            <div className="hidden lg:block w-full lg:w-1/3 p-8 max-w-lg max-h-lg">
              <Link to={`/${makeUrl(title, "idee")}`}>
                <GatsbyImage
                  image={img}
                  className="h-full w-full rounded-lg"
                  alt={`Bild ${title}`}
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
