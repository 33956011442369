import { graphql } from "gatsby";
import React from "react";
import EventList from "../components/EventList";
import ArticleList from "../components/ArticleList";
import IdeaBox from "../components/IdeaBox";
import SiteMetadata from "../components/SiteMetadata";
import Layout from "../layouts/Layout";
import Hero from "../components/Hero";

export default function ArticlePage(props) {
  const { introduction, ideas } = props.data.item;

  return (
    <Layout>
      <Hero image={props.data.item.image.gatsbyImageData}>
        <h2 className="my-36 h1 leading-tight tracking-tight text-center ">
          {props.data.item.title}
          <span className="text-yellow-500">.</span>
        </h2>
      </Hero>
      <div className="container max-w-lg mt-8">
        <div className="text-lg leading-relaxed p-4 pb-10">
          {insertHTML(introduction.introduction)}
        </div>
      </div>

      {ideas.map((idea, i) => (
        <IdeaBox
          title={idea.title.title}
          description={idea.description.description}
          price={idea.price}
          website={idea.website}
          img={idea.image[0].localFile.childImageSharp.gatsbyImageData}
          instruction={idea.instruction}
          even={i % 2}
        />
      ))}
      <SiteMetadata
        title={`${props.data.item.title} -  mit Tipps & Tricks 🤩`}
        description={`Lass dich inspiereren von tollen Geschenkideen! Hier sind ${props.data.item.title}.`}
        image={`${props.data.item.image.file.url}`}
      />
      <ArticleList
        event={props.data.item.events[0].title}
        person={
          props.data.item.persons
            ? props.data.item.persons[0].title.title
            : false
        }
      />
      <EventList items={props.data.events.nodes} />
    </Layout>
  );
}

const insertHTML = (unformatted) => {
  let html = unformatted
    .replace("\n", "<br /><br />")
    .replace(".__", ".</strong>")
    .replace("__", "<strong>");
  return (
    <div className="px-8" dangerouslySetInnerHTML={{ __html: html }}></div>
  );
};

export const query = graphql`
  query ArticleQuery($id: String!) {
    item: contentfulArticle(id: { eq: $id }) {
      introduction {
        introduction
      }
      title
      persons {
        title {
          title
        }
      }
      events {
        title
      }
      image {
        file {
          url
        }
        gatsbyImageData
      }
      ideas {
        title {
          title
        }
        price
        instruction {
          title
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        description {
          description
        }
        website {
          website
        }
      }
    }
    events: allContentfulAnlass(sort: { order: ASC, fields: date }) {
      nodes {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
      }
    }
  }
`;
