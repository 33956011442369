import React from "react";
import Article from "./Article";
import MoreContent from "./BuildingBlocks/MoreContent";
import FadeIn from "./Motion/FadeIn";
import Slider from "react-slick";

export default function EventList({ items }) {
  let articles = items.map((item, i) => (
    <FadeIn key={i} index={i < 5 ? i : 0}>
      <div className="p-4">
        <Article data={item} index={i} type={"anlass"} />
      </div>
    </FadeIn>
  ));

  const settings = {
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: items.length > 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="py-8 max-w-screen-xl w-full mx-auto">
      <div className="section py-4 pt-8 text-2xl font-display">
        Finde passende Geschenke für
      </div>
      <div className="flex items-center">
        <div
          className="max-w-screen-xl"
          style={{ width: "calc(100vw - 2rem)" }}
        >
          <Slider {...settings}>{articles}</Slider>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <MoreContent text={"Alle Anlässe"} path="anlass" />
      </div>
    </div>
  );
}
